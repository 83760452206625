import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from "./app/modules/app.module";
import { environment } from "./environments/environment";

let sentryDsn = "";
if (environment.production) {
    enableProdMode();
    sentryDsn = "https://afa58741935b120224b252913a3427b5@sentry.gmlconsulting.co.uk/10";
}

Sentry.init({
    dsn: sentryDsn,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/api\.kestrel\.com/],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(ref => {
        // Ensure Angular destroys itself on hot reloads.
        if (window["ngRef"]) {
            window["ngRef"].destroy();
        }
        window["ngRef"] = ref;

        // Otherwise, log the boot error
    })
    .catch(err => console.log(err));
